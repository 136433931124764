<template>
  <b-modal
    id="modal-notify"
    dialog-class="notify-modal"
    :title="$t('Notify Me')"
    :visible="show"
    @hide="$emit('close')"
    centered
    hide-footer
    cancel-disabled
  >
    <p>Ontvang een melding zodra het product weer beschikbaar is.</p>
    <div class="notify-input-wrap">
      <div class="notify-input">
        <b-form-input
          id="notify-email"
          v-model.trim="email"
          :state="emailState"
          aria-describedby="invalid-notify-email"
          :placeholder="$t('Enter email to get notified')"
          v-if="!isLoggedIn"
        ></b-form-input>

        <b-form-invalid-feedback id="invalid-notify-email">
          {{ emailError }}
        </b-form-invalid-feedback>
      </div>
      <button class="c-btn c-btn-primary btn-notify" @click="submit">
        {{ $t("notify_btn") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "NotifyModal",
  props: ["show", "productSku"],
  emits: ["close"],
  data: () => ({
    emailReg:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    email: "",
    emailState: null,
    emailError: "",
  }),
  methods: {
    async submit() {
      if (this.email.length == 0 && !this.isLoggedIn) {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
      } else if (!this.emailReg.test(this.email) && !this.isLoggedIn) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
      } else {
        this.emailState = true;
        this.emailError = "";
      }

      if (this.emailState == true) {
        const retval = await this.$store.dispatch("product/addNotifyEmail", {
          email: this.email,
          sku: this.productSku,
        });

        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("Notify Me"),
            text: this.$t("notify_subscribe_success"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.email = "";
          this.$emit("close");
        }
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
};
</script>

<style lang="scss" scoped>
.notify-input-wrap {
  display: flex;
  flex-wrap: wrap;
  .c-btn {
    border-radius: 0px 3px 3px 0px;
    height: 40px;
    width: 100%;
    display: block;
    float: left;
  }
  .notify-input {
    width: 100%;
    margin-bottom: 10px;
    input {
      height: 40px;
      border-radius: 3px 0px 0px 3px;
      display: block;
      float: left;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
}
</style>